import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import Layout from '../components/Layout';
import CategoryTemplateDetails from '../components/CategoryTemplateDetails';

const CategoryTemplate = props => {
  const { data, pageContext } = props;
  const { category, uri, pageNumber } = pageContext;  
  const { wp, wpCategory, site, file } = data;
  const { title: siteTitle, url: siteUrl, language } = wp.generalSettings;
  const { seo } = wpCategory;

  const featuredImage = {
    node: {
      mediaItemUrl: siteUrl + file.publicURL,
      mediaDetails: {
        width: 1920,
        height: 1080,
      },
    },
  }; 

  Object.entries(seo).forEach(([key, value]) => {
    if (!value || value.length === 0)
      delete seo[key];
  });

  const link = siteUrl + uri +  (pageNumber > 0 ? `/Seite/${pageNumber+1}` : '');
  const title = seo.title ?? `${category} - ${parse(siteTitle)}`;
  const description = seo.metaDesc ?? (wpCategory.description || `Alle Beiträge der Kategorie '${category}'`);
  const type = `article`;
  const ogImg = image => image ?? featuredImage.node;

  const jsonLd = {
    "@context": `https://schema.org/`,
    "@type": type,
    url: link,
    headline: title,
    publisher: {
        "@type": `Organization`,
        name: siteTitle,
        logo: {
            "@type": `ImageObject`,
            url: featuredImage.node.mediaItemUrl,
            width: featuredImage.node.mediaDetails.width,
            height: featuredImage.node.mediaDetails.height,
        },
    },
    image: {
      "@type": `ImageObject`,
      url: featuredImage.node.mediaItemUrl,
      width: featuredImage.node.mediaDetails.width,
      height: featuredImage.node.mediaDetails.height,
    },
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <html lang={language} />
          <title>{title}</title>
          <link rel="canonical" href={link} />         
          <link rel="pingback" href="https://xmalanderssein.de/xmlrpc" />      
          <meta name="description" content={description} />          
          <meta name="robots" content={site.siteMetadata.robots.join(',')} />
          <meta property="og:site_name" content={siteTitle} />
          <meta property="og:type" content={type} />
          <meta property="og:title" content={seo.opengraphTitle ?? title} />
          <meta property="og:description" content={seo.opengraphDescription ?? description} />
          <meta property="og:url" content={link} />
          <meta name="twitter:title" content={seo.twitterTitle ?? (seo.opengraphTitle ?? title)} />
          <meta name="twitter:description" content={seo.twitterDescription ?? (seo.opengraphDescription ?? description)} />
          <meta name="twitter:url" content={link} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={ogImg(seo.twitterImage).mediaItemUrl} />
          <meta property="og:image" content={ogImg(seo.opengraphImage).mediaItemUrl} />
          <meta property="og:image:width" content={ogImg(seo.opengraphImage).mediaDetails.width} />
          <meta property="og:image:height" content={ogImg(seo.opengraphImage).mediaDetails.height} />
					<meta name="google-site-verification" content="l5FCVcaO9LO7rocX46rGmx_wtbWEjqUOTQE4DibrMJA" />
          <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
        </Helmet>
        <CategoryTemplateDetails {...props} />
      </div>
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query($category: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        adminUrl   
        rss
        robots
      }
    }
    file(name: {eq: "logo-wp"}) {
      publicURL
    }
    wp {
      generalSettings {
        description
        language
        title
        url
      }
    }
    wpCategory(name: {eq: $category}) {
      name
      link
      description
      seo {
        ...WpTaxonomySEOFragment
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: $category}}}}}
      limit: $limit
      sort: {fields: date, order: DESC}
      skip: $skip
    ) {
      edges {
        node {
          title
          date(formatString: "DD.MM.YYYY")
          excerpt
          slug
          seo {
            readingTime
          }
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              title
              localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: TRACED_SVG
                      layout: CONSTRAINED
                      transformOptions: {fit: CONTAIN}
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
            }
          }
        }
      }
    }
    allWpPage {
      edges {
        node {
          uri
          title
        }
      }
    }
    allWpCategory {
      nodes {
        name
        uri
      }
    }
  }
`;
