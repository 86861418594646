import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const PostPreview = ({ post, position, count }) => {
  const { title, date, excerpt, slug, featuredImage } = post;

  const uri = `/blog/${date.split('.').reverse().join('/')}/${slug}`;  
  const ReadingTime = time => {
    if (time === undefined) return <span />;
	
    return <span>{time} {time !== 1 ? 'Minuten' : 'Minute'} Lesezeit</span>;
  }  

  const cssName = () => {    
    if (position !== count) return '';

    const cols = (8 - count) % 3 === 0 || count === 8
                  ? 3 : (8 - count) % 3 

    return `cols-${cols}`;
  };

  return (    
      <article className={`post-preview ${cssName()}`}>
        <figure className="post-preview__image">
          { featuredImage 
            ? <GatsbyImage image={featuredImage.node.localFile?.childImageSharp.gatsbyImageData} loading="lazy" alt={title} />
            : <div />
          }          
        </figure>
        <div>
          <header className="post-preview__header">          
            <small className="post__meta">
              <time dateTime={date}>
                {date}
              </time>            
            </small>       
            <a href={uri}>
              <h2>
                {title}
              </h2>
            </a>            
          </header>
          <section
            className="longform longform--short"            
            dangerouslySetInnerHTML={{ __html: excerpt.replace(/\s\[&hellip;]/, '...') }}
          />   
          <footer style={{padding: '15px 0 0'}}>
            <a className="button floatright" href={uri}>Zum Artikel ►</a>
          </footer>
        </div>         
      </article>
  );
};

export default PostPreview;
