import React from 'react';
import PostPreview from './PostPreview';

const Feed = props => {
  const { posts } = props;
  if (!posts.length) {
    const message = "Keine Beiträge vorhanden.";
    return (
      <p className="post-preview" style={{ textAlign: 'center' }}>
        {message}
      </p>
    );
  }
  return posts.map((post, index) => <PostPreview key={post.slug} position={index + 1} count={posts.length} post={post} />);
};

export default Feed;
