import React from 'react';
import { Link } from 'gatsby'

export default function Pagination({ pageContext }) {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

  return (
    <nav className="pagination container container--wide" role="navigation" style={{display: 'flex', justifyContent: 'space-between', marginBottom: '2em'}}>
      <div style={{flex: 1, justifyContent: 'center'}}>
        {previousPagePath && (

            <Link className="button" to={previousPagePath} rel="prev">
                    zurück
            </Link>

        )}
      </div>
      {numberOfPages > 1 && <div className="pagination-location" style={{alignItems: 'center', flex: 1, justifyContent: 'center'}}>Seite {humanPageNumber} von {numberOfPages}</div>}      
      <div style={{flex: 1, justifyContent: 'center'}}>
        {nextPagePath && (

            <Link className="button" to={nextPagePath} rel="next">
                    weiter
            </Link>
        )}
      </div>      
    </nav>
  );
}