import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

export default function InstaFeed() {
  // const containerRef = useRef();
  // const [data, setData] = useState();

  // let scrolling = false;

  // useEffect(() => {
  //   const containerEl = containerRef?.current;
  //   const onSwipe = e => {
  //     const { detail } = e;
      
  //     if (typeof e.preventDefault === 'function') 
  //       e.preventDefault();

  //     if (!containerEl) return;

  //     const { scrollLeft } = containerEl;
  //     const rect = containerEl.querySelector('div').getClientRects()[0];      
  //     const elWidth = rect.width / data.length;

  //     if (detail.dir === 'left') {
  //       containerEl.scrollTo(Math.round(scrollLeft / elWidth) * elWidth + window.innerWidth - (window.innerWidth / elWidth > 3 ? elWidth : 0), 0);
  //     } else {
  //       containerEl.scrollTo(Math.round(scrollLeft / elWidth) * elWidth - window.innerWidth + (window.innerWidth / elWidth > 3 ? elWidth : 0), 0);
  //     }
  //   };
  //   const onMouseMove = e => {    
  //     const detail = {
  //       dir: e.movementX > 0 ? 'right' : 'left'
  //     };
     
  //     containerEl.removeEventListener('mousemove', onMouseMove);
  //     onSwipe({ detail });
  //   }
  //   const onTouchStart = e => e.prevenDefault();
  //   const onMouseDown = () => containerEl.addEventListener('mousemove', onMouseMove);
  //   const onMouseUp = e => {
  //     if (scrolling) {
  //       e.preventDefault();        
  //       containerEl.removeEventListener('mousemove', onMouseMove);
  //       scrolling = false;
  //     }      
  //   };
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch(`https://xmalanderssein.de/api/instagram/xmalanderssein`, {
  //         method: 'GET',
  //         mode: 'cors',
  //       });

  //       const json = await res.json();                    
  //       setData(json.media);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   if (!data) fetchData();

  //   if (containerEl) {
  //     containerEl.addEventListener('swiped', onSwipe);
  //     containerEl.addEventListener('ontouchstart', onTouchStart);      
  //     containerEl.addEventListener('mousedown', onMouseDown);
  //     containerEl.addEventListener('mouseup', onMouseUp);
  //   }

  //   return (() => {
  //     if (containerEl) {
  //       containerEl.removeEventListener('swiped', onSwipe);
  //       containerEl.removeEventListener('ontouchstart', onTouchStart);
  //       containerEl.removeEventListener('mousedown', onMouseDown);
  //       containerEl.removeEventListener('mouseup', onMouseUp);
  //       containerEl.removeEventListener('mousemove', onMouseMove);
  //     }
  //   })
  // }, [data, scrolling]);
 
  return (
    <>
      {/* <div className="container container--wide">
        <h3>Folge <a href="https://www.instagram.com/xmalanderssein/" target="_blank" rel="noreferrer" external="true">xmalanderssein</a> auf <span className="inline--block"><FontAwesomeIcon icon={['fab', 'instagram-square']} size="lg" /> Instagram</span></h3>
      </div>
      <section ref={containerRef} className="insta-feed--container">
        <div style={{width: data ? 'max-content' : 'auto', textAlign: 'center'}}>
        {data ? data.map(pic => {
            if (pic === null) {
              setData(false);
              return <div />;
            }
            return (
              <figure key={pic.shortcode}>
                <a href={`https://instagram.com/p/${pic.shortcode}`} alt="xmalanderssein on Instagram" target="_blank" rel="noreferrer" external title="xmalanderssein auf Instagram">
                  <img onDragStart={e => e.preventDefault()} src={`https://xmalanderssein.de/api/static/${pic.shortcode}.jpg?${pic.display_url}`} alt="xmalanderssein auf Instagram" />
                </a>
             </figure>
           );
          }
        ) : <FontAwesomeIcon icon="atom" size="5x" className="fa-spin faded" style={{margin: '0.5em 0'}} />}
        </div>
      </section>  */}
    </>
  );
}